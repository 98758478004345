
import { defineComponent, onMounted } from "vue";
import KTTableWidgetProfitLoss from "@/components/widgets/tables/WidgetProfitLoss.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetProfitLoss,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Profit & Loss", ["Financial Statements","Income Statements"]);
    });
  },
});
