
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Information1 from "@/components/dropdown/Information1.vue";
import ElCurrencyInput from "@/components/CurrencyInput.vue";
import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";
import ElInfo from "@/components/ElInfo.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";

export default defineComponent({
name: "ProfitLossNew",
components: {
  Field,
  Form,
  ElCurrencyInputNoSymbol,
},
async mounted() {
  this.init();
  this.initMonths();
},
data() {
  const schema = Yup.object({});

  const months = [
      "Jan",
      "Feb",
      "Mac",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
  ];

  const statements = {
      incomeStatement: {
          label: "Income Statement",
          child: {
            sales: {
                label: "Sales",
                value: [],
            },
            costSales: {
                label: "(-) Cost of Sales",
                value: [],
            },
            grossProfitLoss: {
                label: "Gross Profit / (Loss)",
                value: [],
            },
            operatingExpenses: {
                label: "(-) Operating Expenses",
                value: [],
            },
            otherIncome: {
                label: "(+) Other Income",
                value: [],
            },
            depreciation: {
                label: "(-) Depreciation",
                value: [],
            },
            profitLossInterestTax: {
                label: "Profit / (Loss) before Interest and Tax",
                value: [],
            },
            interestExpense: {
                label: "(-) Interest Expense",
                value: [],
            },
            profitLossTax: {
                label: "Profit / (Loss) before Tax",
                value: [],
            },
            incomeTaxZakat: {
                label: "(-) Income Tax/ Zakat",
                value: [],
            },
            netProfitLoss: {
                label: "Net Profit / (Loss) after Tax",
                value: [],
            },
          }
      },
  };

  return {
    schema:schema,
    months,
    statements,
  };
},
setup() {
  return {};
},
methods: {
  init() {
    setCurrentPageBreadcrumbs("Income Statement", ["Financial Statements"]);
  },
  onSubmit(values){
    console.log(values);
  },

  initMonths() {

      for (let key of Object.keys(this.statements.incomeStatement.child)) {
          let incomeStatementChild = this.statements.incomeStatement.child;
          for (let index = 0; index < this.months.length; index++) {
              incomeStatementChild[key].value.push(0);
          }
      }
  },

},
});
